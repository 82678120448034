import React, { useState, useEffect, useContext } from "react";
import { Pagination } from "../components";
import moment from "moment";
import { useNavigate, useLocation } from "react-router-dom";
import { AppLayout } from "../components";
import { SiteContext } from "../context";
import ReactModal from "react-modal";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const validationSchema = Yup.object().shape({
    title: Yup.string()
      .label("Title")
      .required()
      .min(3, "Must have at least 3 characters"),
    sender: Yup.string().label("Please enter your name").required(),
  });

const AdminTributes = () => {
  let navigate = useNavigate();
  let location = useLocation();

  const { apiUrl, slugify } = useContext(SiteContext);

  const userData = JSON.parse(localStorage.getItem("user"));

  const [tributes, setTributes] = useState([]);
  const [story, setStory] = useState("");
  const [total, setTotal] = useState(null);
  const [limit, setLimit] = useState(50);
  const [page, setPage] = useState(1);
  const [cangoBack, setCangoBack] = useState(true);
  const [cangoForward, setCangoForward] = useState(true);
  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    getTributes();
  }, [page, limit]);

  const getTributes = () => {
    fetch(
      `${apiUrl}/api/tributes.php?action=fetchpublic&page=${page}&limit=${limit}`,
      {
        method: "get",
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.rows.length > 0) {
            setTributes(res.rows);
            setTotal(res.rows.length);
          }
        } else if (res.status === 400) {
          setMsg(res.msg);
          setTimeout(() => {
            setMsg("");
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = (
    values,
    { setSubmitting, resetForm, selectedPackage, selectedUser }
  ) => {
    if (story === "" || story === undefined) {
      alert("Please provide the content for the tribute.");
      setSubmitting(false);
      return;
    }

    if(values.photo) {
      const docType = values.photo.name.split(".").pop();
      const docExtenstions = ["pdf"];
  
      // alert(docType + +values.photo.size);
      if (!docExtenstions.includes(docType)) {
        alert("Only PDF files are allowed");
        setSubmitting(false);
        return;
      }
  
    }

    if(values.audio) {
      const audioType = values.audio.name.split(".").pop();
      const audioExtenstions = ["mp3", "wav", "m4a"];
  
      // alert(docType + +values.photo.size);
      if (!audioExtenstions.includes(audioType)) {
        alert("Only mp3 and wav audio files are allowed");
        setSubmitting(false);
        return;
      }
  
    }

    let formData = new FormData();

    formData.append("title", values.title);
    formData.append("titleslug", slugify(values.title));
    formData.append("tribute", story);
    formData.append("document", values.photo);
    formData.append("audio", values.audio);
    formData.append("sendername", values.sender);
    formData.append("role", 'visitor');
    formData.append("action", "create");

    return fetch(`${apiUrl}/api/tributes.php`, {
      method: "post",
      body: formData,
    })
      .then((res) => res.json())
      .then(async (res) => {

        if (res.status === 200) {
          resetForm({});
          setSubmitting(false);
          setMsg(res.msg);
          setTimeout(() => {
            setShowModal(false);
            setMsg("");
            getTributes();
          }, 3000);
        } else if (res.status === 400) {
          setErrorMsg(res.msg);
          setTimeout(() => {
            setErrorMsg("");
          }, 5000);
          setSubmitting(false);
        } else {
          alert(
            "Tribute submissio failed",
            "Error connection to the server please check your details and try again",
            [{ text: "Okay" }]
          );
        }
      })
      .catch((error) => {
        console.log("Api call error", error.message);
      });
  };


  const doPrevious = async () => {
    if (parseInt(page) > 1) {
      await setPage(() => parseInt(page - 1));
      setCangoBack(true);
      setCangoForward(true);
    } else {
      setCangoBack(false);
      setCangoForward(true);
    }
  };

  const doNext = async () => {
    if (parseInt(page * limit) < total) {
      await setPage(() => parseInt(page + 1));
      setCangoForward(true);
      setCangoBack(true);
    } else {
      setCangoForward(false);
      setCangoBack(true);
    }
  };

  const handleChange = (event) => {
    let newLimit = event.target.value;
    setLimit(newLimit);
  };


  return (
    <div>
      <ReactModal
        isOpen={showModal}
        ariaHideApp={false}
        style={{
          overlay: {
            flex: 1,
            justifyContent: "center",
            alignContent: "center",
            position: "fixed",
            top: "10%",
            bottom: "10%",
            left: "5%",
            right: "5%",
            width: "90%",
            height: '80%',
            backgroundColor: "rgba(255, 255, 255, 0.75)",
          },
          content: {
            position: "absolute",
            top: "20px",
            left: "10px",
            right: "10px",
            bottom: "20px",
            border: "1px solid #ccc",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            borderRadius: "4px",
            outline: "none",
            padding: "20px",
          },
        }}
      >
                    <div className="container my-2">

              <div className="my-2 d-flex justify-content-between">
                <div className="align-items-center">
                  
                  {errorMsg !== "" ? (
                    <div className="p-2">
                      <span className="p-2 border border-info text-dark">
                        {errorMsg}
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>

              <Formik
                initialValues={{
                  title: "",
                  photo: "",
                  sender: '',
                }}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  handleSubmit(values, { setSubmitting, resetForm });
                }}
              >
                {({
                  handleChange,
                  values,
                  handleSubmit,
                  errors,
                  isValid,
                  isSubmitting,
                  handleBlur,
                  touched,
                  setFieldValue,
                }) => (
                  <div>
                    <form onSubmit={handleSubmit} encype="multipart/form-data">
                      <div className="my-2">
                          <div className="form-group my-2">
                            <div>
                              <label htmlFor="title">Title</label>
                            </div>
                            <Field
                              id="title"
                              name="title"
                              value={values.title}
                              onBlur={handleBlur("title")}
                              autoCapitalize="none"
                              style={{
                                padding: 4,
                              }}
                              className="form-control"
                            />
                            {touched.title && errors.title && (
                              <div className="py-1">
                                <span className="text-danger">
                                  {touched.title && errors.title}
                                </span>
                              </div>
                            )}
                          </div>

                          <div className="form-group my-1">
                            <div>
                              <label htmlFor="pagestory">Tribute</label>
                            </div>
                            <ReactQuill
                              theme="snow"
                              value={story}
                              onChange={setStory}
                            />
                          </div>

                          <div className="form-group my-2">
                            <div>
                              <label htmlFor="sender">Name</label>
                            </div>
                            <Field
                              id="sender"
                              name="sender"
                              value={values.sender}
                              onBlur={handleBlur("sender")}
                              autoCapitalize="none"
                              style={{
                                padding: 4,
                              }}
                              className="form-control"
                            />
                            {touched.sender && errors.sender && (
                              <div className="py-1">
                                <span className="text-danger">
                                  {touched.sender && errors.sender}
                                </span>
                              </div>
                            )}
                          </div>


                          <div className="form-group my-3">
                            <div>
                              <label htmlFor="photo"> Upload tribute (pdf)</label>
                            </div>
                            <input
                              id="photo"
                              name="photo"
                              type="file"
                              onChange={(event) => {
                                setFieldValue(
                                  "photo",
                                  event.currentTarget.files[0]
                                );
                              }}
                            />
                            
                          </div>

                          <div className="form-group my-3">
                            <div>
                              <label htmlFor="audio"> Upload audio tribute (mp3, wav, m4a))</label>
                            </div>
                            <input
                              id="audio"
                              name="audio"
                              type="file"
                              onChange={(event) => {
                                setFieldValue(
                                  "audio",
                                  event.currentTarget.files[0]
                                );
                              }}
                            />
                            </div>

                      </div>
                      <button
                        type="submit"
                        onClick={handleSubmit}
                        title="Submit"
                        disabled={!isValid || isSubmitting}
                        className="btn btn-primary"
                      >
                        Submit
                      </button>

                      <button
                        onClick={() => setShowModal(false)}
                        title="Cancel"
                        className="btn btn-secondary mx-2"
                      >
                        Cancel
                      </button>
                    </form>
                  </div>
                )}
              </Formik>
            </div>

      </ReactModal>
      <div className="max-w-[1200px] mx-auto">
        <div className="row">
            <div className="container my-2">
              <div className="my-2 d-flex justify-content-between">
                <div className="align-items-center">
                  {msg !== "" ? (
                    <div className="p-2">
                      <span className="p-2 border border-info text-dark">
                        {msg}
                      </span>
                    </div>
                  ) : null}
                  {errorMsg !== "" ? (
                    <div className="p-2">
                      <span className="p-2 border border-info text-dark">
                        {errorMsg}
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="flex justify-between items-center p-2">
                <div><h3>Tributes</h3></div>

                 <div> <button
                    className="bg-slate-300 text-dark px-3 py-2 rounded"
                    onClick={() => setShowModal(true)}
                  >
                   <i className="bi bi-pencil px-2"></i> Write a tribute
                  </button>
                  </div>
                </div>



             
              {tributes && (
                <div className="w-full md:px-5 p-4">

                  {tributes.map((tribute, i) => {
                    return (
                      <div key={i} className="row py-2 border border-1 my-3 rounded-4">
                        <div className="col-md-3 px-3 py-1">
                        <div className="flex justify-between items-center md:flex-col md:justify-start md:items-start"><h4>{tribute.tribute_sender}</h4>
                            <p>{moment(tribute.createdAt).format(
                              "Do MMM YYYY"
                            )}</p></div>
                          </div>
                          <div className="col-md-9 px-3">
                           
                        
                            <h1 className="text-2xl my-1 font-medium">{tribute.title}</h1>
                          <div dangerouslySetInnerHTML={{__html: tribute.tribute}} />
                          <div className="flex justify-between items-center small">
                            
                            {tribute.tribute_pdf !== '' && tribute.tribute_pdf !== null && <p><a className="bg-blue-600 text-decoration-none px-3 py-2 rounded text-slate-100" href={`${apiUrl}/${tribute.tribute_pdf}`} target={'_blank'}>View tribute (in pdf)</a></p>}
                            {tribute.tribute_audio !== '' && tribute.tribute_audio !== null && 
                              <audio controls src={`${apiUrl}/${tribute.tribute_audio}`} >
                              <a href={`${apiUrl}/${tribute.tribute_audio}`} > Download audio </a>
                            </audio>
                            }
                            
                          </div>
                        </div>
   
                      </div>
                    );
                  })}
                </div>
              )}

              {tributes.length > 0 && (
                <Pagination
                  limit={limit}
                  page={page}
                  total={total}
                  cangoBack={cangoBack}
                  cangoForward={cangoForward}
                  handleChange={handleChange}
                  doNext={doNext}
                  doPrevious={doPrevious}
                />
              )}

            </div>
        </div>
      </div>
    </div>
  );
};

export default AppLayout(AdminTributes);
