import React from "react";
import { Link } from "react-router-dom";
import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaLinkedin,
  FaYoutube,
} from "react-icons/fa";
import QRCode from "react-qr-code";

import { MemberServices, MemberPortfolio, MemberProducts } from ".";
import { getHeading } from "../utils";

const MemberProfile = ({ apiUrl, item }) => {
  if (item === null) {
    return null;
  }

  return (
    <div className="static w-full w-max-[1300px] mx-auto">
      <div className="row bg-gray-100 h-screen">
        <div className="col-md-7 p-5">
          <h1 className="my-2 py-2 font-bold uppercase">{item.name}</h1>
          <p className="text-lg">{item.profile.split(" ", 50).join(" ")}</p>

          <div className="flex my-3">
            {item.facebook_link !== "" &&
              item.facebook_link !== null &&
              item.facebook_link !== undefined && (
                <div className="h-14 w-14 flex justify-center items-center text-center bg-gray-700 rounded-full">
                  <a
                    className="h-13 w-13"
                    href={`${item.facebook_link}`}
                    target="_blank"
                  >
                    <FaFacebook size={30} color="#fff" />
                  </a>
                </div>
              )}
            {item.twitter_link !== "" &&
              item.twitter_link !== null &&
              item.twitter_link !== undefined && (
                <div className="ml-2 h-14 w-14 flex justify-center items-center text-center bg-gray-700 rounded-full">
                  <a
                    className="h-13 w-13"
                    href={`${item.twitter_link}`}
                    target="_blank"
                  >
                    <FaTwitter size={30} color="#fff" />
                  </a>
                </div>
              )}
            {item.instagram_link !== "" &&
              item.instagram_link !== null &&
              item.instagram_link !== undefined && (
                <div className="ml-2 h-14 w-14 flex justify-center items-center text-center bg-gray-700 rounded-full">
                  <a
                    className="h-13 w-13"
                    href={`${item.instagram_link}`}
                    target="_blank"
                  >
                    <FaInstagram size={30} color="#fff" />
                  </a>
                </div>
              )}
            {item.linkedin_link !== "" &&
              item.linkedin_link !== null &&
              item.linkedin_link !== undefined && (
                <div className="ml-2 h-14 w-14 flex justify-center items-center text-center bg-gray-700 rounded-full">
                  <a
                    className="h-13 w-13"
                    href={`${item.linkedin_link}`}
                    target="_blank"
                  >
                    <FaLinkedin size={30} color="#fff" />
                  </a>
                </div>
              )}
            {item.youtube_link !== "" &&
              item.youtube_link !== null &&
              item.youtube_link !== undefined && (
                <div className="ml-2 h-14 w-14 flex justify-center items-center text-center bg-gray-700 rounded-full">
                  <a
                    className="h-13 w-13"
                    href={`${item.youtube_link}`}
                    target="_blank"
                  >
                    <FaYoutube size={30} color="#fff" />
                  </a>
                </div>
              )}
          </div>
        </div>
        <div className="col-md-5">
          {item.full_photo}
          <div className="flex flex-col justify-end items-end">
            {item.full_photo !== "" &&
              item.full_photo !== undefined &&
              item.full_photo !== null &&
              item.full_photo !== "images/noimage.png" && (
                <img
                  src={`${apiUrl}/${item.full_photo}`}
                  height="auto"
                  width={600}
                  className="absolute opacity-20 md:opacity-90 img-fluid top-20 right-[20px] rounded p-1"
                  alt="picture"
                />
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MemberProfile;
