import React from "react";
import { Button, NavBar, Footer, SideBar } from "./";

const AppLayout = (Component) =>
  function HOC() {
    return (
      <div>
        <div className="mb-1">
          <NavBar />
        </div>
        <div className="max-w-[1200px] mx-auto min-h-screen my-3 p-1 md:p-3">
          <div className="p-1">
          <div className="grid grid-cols-5 gap-1 tems-center bg-sky-500 px-1 pt-1 mt-4">
              <Button title='About' /> <Button title='Tributes' /> <Button title='Events' /> <Button title='Photos' /> <Button title='Videos' />
            </div>   
           <div className="row">
            <div className="col-md-8 p-1 md:p-2"><Component /></div>
            <div className="col-md-4 p-2 md:p-4"><SideBar /></div>
            </div> 
          </div>
        </div>
        <div>
          <Footer />
        </div>
      </div>
    );
  };

export default AppLayout;
