import React, { useState, useEffect, useContext } from "react";
import { Header, Footer, ProjectGallery } from "./";
import { Link, useNavigate, useParams } from "react-router-dom";
import { SiteContext } from "../context";
import {
  ImageDisplay,
  SubCategoryNewsDisplay,
  BannerSide,
  BannerTop,
  AppLayout,
} from "../components";
import { Dropdown } from "react-bootstrap";
import moment from "moment";
import Seo from "./Seo";

const Events = () => {
  const { userData, apiUrl, siteconfig, handleCurrentPage, isMobile } =
    useContext(SiteContext);

  const navigate = useNavigate();

  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    getEvents();
  }, []);

  const getEvents = () => {
    setLoading(true);

    fetch(`${apiUrl}/api/events.php?action=fetch_events`, {
      method: "get",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 200) {
          setEvents(res.rows);
          setLoading(false);
        } else if (res.status === 400) {
          setErrorMsg(res.msg);
          setLoading(false);
          setTimeout(() => {
            setErrorMsg("");
          }, 5000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const capitaliseText = (text) => {
    if (text === "") {
      return text;
    }
    let firstString = text.charAt(0);
    return firstString.toUpperCase() + "" + text.slice(1);
  };

  return (
    <div>
      <Seo
        title={`Home | ${siteconfig.shopname}`}
        description={siteconfig.shopdescription}
        url={`${apiUrl}`}
        img={`${apiUrl}/${siteconfig.shoplogo}`}
      />

      {loading && (
        <div className="d-flex justify-content-center align-content-center my-5">
          <div class="spinner-border text-secondary" role="status" />
        </div>
      )}

      {errorMsg !== "" && <div className="container">{errorMsg}</div>}


      <div className="container">
        <div className="my-1">
          {events.length > 0 && (
              <div className="row">
                {events.map((item, i) => {
                  return (
                    <div
                      key={i}
                    className="border border--2 p-3 md:p-5 rounded-3 my-3"
                    >
                      <Link
                        key={i}
                        className="text-decoration-none text-dark"
                        to={`/event/${item.eventtitle_slug}`}
                      >
                        {item.e_photo !== '' && item.e_photo !== null && (
                          <ImageDisplay
                            url={`${apiUrl}/${item.e_photo}`}
                            title={item.eventtitle}
                            category=""
                            height={210}
                          />
                        )}
                        <div
                          className={
                            isMobile ? "h5 py-2 border-bottom" : "h5 py-2"
                          }
                        >
                          {item.eventtitle}
                        </div>

                        <div dangerouslySetInnerHTML={{__html: item.eventdesc.split(" ", 30).join(" ")}} />

                        <div className="my-3 py-2">
              <p>Date: {moment(item.e_date_start).format('Do MMM YYYY')}</p>
              <p>Time: {item.e_time_start} to {item.e_time_end}</p>
              <p>Venue: {item.e_place}</p>
            </div>
                      </Link>
                    </div>
                  );
                })}
              </div>
            )}

        </div>
      </div>
    </div>
  );
};

export default AppLayout(Events);
