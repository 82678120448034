import React, { useState, useContext } from "react";
import { HiMenuAlt4, HiX } from "react-icons/hi";
import { FaBars, FaSearch, FaArrowLeft, FaHome } from "react-icons/fa";
import { motion } from "framer-motion";
import { Dropdown } from "react-bootstrap";
import { logo } from "../constants";
import { SearchBox } from "./";
import { useNavigate, Link, NavLink } from "react-router-dom";
import { SiteContext } from "../context/index";

const Navbar = () => {
  const { isLoggedOn, userData, apiUrl, handleSignOut } =
    useContext(SiteContext);
  const navigate = useNavigate();

  const [toggle, setToggle] = useState(false);
  const [showSearchBox, setShowSearchBox] = useState(false);

  const doSignOut = () => {
    navigate("/");
    handleSignOut();
  };

  const handleToggle = () => {
    setToggle((currToggle) => !currToggle);
  };

  const handleSubmit = (search) => {
    if (search !== "") {
      navigate("/search", { state: search });
      setShowSearchBox(false);
    }
  };

  return (
    <nav className="bg-sky-600 w-full h-220 md:shadow-lg text-white mb-7 rounded-b-lg">
      <div className="mx-auto">
        <div className="flex h-[200px] md:h-[200px] justify-center items-center">
        <div className="font-bold text-center">
            <Link to={"/"} className="text-decoration-none text-white">
              <div className="pt-10 flex justify-center items-center">
                <img
                  src={logo}
                  alt="logo"
                  className="h-36 w-36 md:w-64 md:h-64 rounded-full"
                />
              </div>
            </Link>
          </div>
          <div className="flex flex-col justify-center items-center">
          <div className="flex flex-col justify-center items-center">
            <h1 className="text-2xl md:text-3xl font-bold">Lucy Aku Anikpo</h1>
            <h4 className="text-xl md:text-2xl">{`1947 - 2023`}</h4>
          </div>          
        </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
