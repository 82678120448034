import React, { useState, useEffect, useContext } from "react";
import { Header, Footer } from "./";
import { useNavigate, useLocation } from "react-router-dom";
import { Formik, Field } from "formik";
import { DashboardMenu, NavigationBar, AppLayout } from "../components";
import { SiteContext } from "../context";
import * as Yup from "yup";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .label("Title")
    .required()
    .min(3, "Must have at least 3 characters"),
  sender: Yup.string().label("Sender").required(),
});

const AdminTributeUpdate = () => {
  let { tribute } = useLocation().state;
  let navigate = useNavigate();

  const { apiUrl, userData, slugify } = useContext(SiteContext);

  const [story, setStory] = useState("");
  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    setStory(tribute.tribute);
  }, []);

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    if (story === "" || story === undefined) {
      alert("Please provide the content for the article.");
      setSubmitting(false);
      return;
    }

    if(values.photo) {
      const docType = values.photo.name.split(".").pop();
      const docExtenstions = ["pdf"];
  
      // alert(docType + +values.photo.size);
      if (!docExtenstions.includes(docType)) {
        alert("Only PDF files are allowed");
        setSubmitting(false);
        return;
      }
  
    }

    if(values.audio) {
      const audioType = values.audio.name.split(".").pop();
      const audioExtenstions = ["mp3", "wav", "m4a"];
  
      // alert(docType + +values.photo.size);
      if (!audioExtenstions.includes(audioType)) {
        alert("Only mp3, wav and m4a audio files are allowed");
        setSubmitting(false);
        return;
      }
  
    }

    let formData = new FormData();

    formData.append("tributeid", values.tributeid);
    formData.append("title", values.title);
    formData.append("titleslug", slugify(values.title));
    formData.append("tribute", story);
    formData.append("document", values.photo);
    formData.append("audio", values.audio);
    formData.append("sendername", values.sender);
    formData.append("status", values.status);
    formData.append("role", userData.role);
    formData.append("action", "update");

    return fetch(`${apiUrl}/api/tributes.php`, {
      method: "post",
      body: formData,
    })
      .then((res) => res.json())
      .then(async (res) => {
        console.log(res);

        if (res.status === 200) {
          resetForm({});
          setSubmitting(false);
          setMsg(res.msg);
          setTimeout(() => {
            setMsg("");
            navigate("/admintributes");
          }, 3000);
        } else if (res.status === 400) {
          setErrorMsg(res.msg);
          setTimeout(() => {
            setErrorMsg("");
          }, 5000);
          setSubmitting(false);
        } else {
          alert(
            "Tribute update failed",
            "Error connection to the server please check your details and try again",
            [{ text: "Okay" }]
          );
        }
      })
      .catch((error) => {
        console.log("Api call error", error.message);
      });
  };

  return (
    <div>
      <div>
        <div className="row">
          <div className="col-md-2 bg-light">
            <DashboardMenu />
          </div>
          <div className="col-md-10">
            <div className="container my-2">
              <NavigationBar section={"Tributes"} goback={true} />

              <h3>Tribute Manage</h3>
              <div className="my-2 d-flex justify-content-between">
                <div className="align-items-center">
                  {msg !== "" ? (
                    <div className="p-2">
                      <span className="p-2 border border-info text-dark">
                        {msg}
                      </span>
                    </div>
                  ) : null}
                  {errorMsg !== "" ? (
                    <div className="p-2">
                      <span className="p-2 border border-info text-dark">
                        {errorMsg}
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>

              <Formik
                initialValues={{
                  tributeid: tribute.tributeid != "" ? tribute.tributeid : "",
                  title: tribute.title != "" ? tribute.title : "",
                  photo: "",
                  audio: "",
                  sender:
                    tribute.tribute_sender != ""
                      ? tribute.tribute_sender
                      : '',
                  status:
                    tribute.tribute_status != ""
                      ? tribute.tribute_status
                      : '',
                }}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  handleSubmit(values, { setSubmitting, resetForm });
                }}
              >
                {({
                  handleChange,
                  values,
                  handleSubmit,
                  errors,
                  isValid,
                  isSubmitting,
                  handleBlur,
                  touched,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit} encype="multipart/form-data">
                    <div className="my-2">

                      <div className="form-group my-2">
                            <div>
                              <label htmlFor="title">Title</label>
                            </div>
                            <Field
                              id="title"
                              name="title"
                              value={values.title}
                              placeholder="Title"
                              onBlur={handleBlur("title")}
                              autoCapitalize="none"
                              style={{
                                padding: 4,
                              }}
                              className="form-control"
                            />
                            {touched.title && errors.title && (
                              <div className="py-1">
                                <span className="text-danger">
                                  {touched.title && errors.title}
                                </span>
                              </div>
                            )}
                          </div>

                          <div className="form-group my-1">
                            <div>
                              <label htmlFor="pagestory">Tribute</label>
                            </div>
                            <ReactQuill
                              theme="snow"
                              value={story}
                              onChange={setStory}
                            />
                          </div>

                          <div className="form-group my-2">
                            <div>
                              <label htmlFor="sender">Sender</label>
                            </div>
                            <Field
                              id="sender"
                              name="sender"
                              value={values.sender}
                              placeholder="sender"
                              onBlur={handleBlur("sender")}
                              autoCapitalize="none"
                              style={{
                                padding: 4,
                              }}
                              className="form-control"
                            />
                            {touched.sender && errors.sender && (
                              <div className="py-1">
                                <span className="text-danger">
                                  {touched.sender && errors.sender}
                                </span>
                              </div>
                            )}
                          </div>


                        <div className="form-group my-3">
                          <div>
                            <label htmlFor="photo"> Upload pdf</label>
                          </div>
                          <input
                            id="photo"
                            name="photo"
                            type="file"
                            onChange={(event) => {
                              setFieldValue(
                                "photo",
                                event.currentTarget.files[0]
                              );
                            }}
                          />
                          {tribute && tribute.tribute_pdf !== '' && tribute.tribute_pdf !== null && (
                            <p>
                              {`${apiUrl}/${tribute.tribute_pdf}`}
                              </p>
                          )}
                        </div>

                      <div className="form-group my-3">
                            <div>
                              <label htmlFor="audio"> Upload audio tribute (mp3, wav, m4a)</label>
                            </div>
                            <input
                              id="audio"
                              name="audio"
                              type="file"
                              onChange={(event) => {
                                setFieldValue(
                                  "audio",
                                  event.currentTarget.files[0]
                                );
                              }}
                            />
                             {tribute && tribute.tribute_audio !== '' && tribute.tribute_audio !== null && (
                            <p>
                              {`${apiUrl}/${tribute.tribute_audio}`}
                              </p>
                          )}
                            </div>

                            <div className="form-group my-1">
                            <div>
                              <label htmlFor="status">Status</label>
                            </div>
                            <Field
                              as="select"
                              id="status"
                              name="status"
                              value={values.status}
                              onChangeText={handleChange("status")}
                              style={{
                                padding: 4,
                              }}
                              className="form-control"
                            >
                              <option value="Pending">Pending</option>
                              <option value="Approved">Approved</option>
                            </Field>
                          </div>


                    </div>
                    <button
                      type="submit"
                      onClick={handleSubmit}
                      title="Submit"
                      disabled={!isValid || isSubmitting}
                      className="btn btn-primary"
                    >
                      Submit
                    </button>

                    <button
                      onClick={() => navigate(-1)}
                      title="Cancel"
                      className="btn btn-secondary mx-2"
                    >
                      Cancel
                    </button>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppLayout(AdminTributeUpdate);
