import { portflioCategories } from "./categories";

const logo = require("./images/lucy.png");
const pic1 = require("./images/pic1.jpeg");
const pic2 = require("./images/pic2.jpeg");
const pic3 = require("./images/1212121213.jpg");
const pic4 = require("./images/pic4.jpeg");
const oluebube = require("./images/olu-ebube-patty-obasi.mp3");


export { logo, portflioCategories, pic1, pic2, pic3, pic4, oluebube };
