import React, { useState, useEffect, useContext, useRef } from "react";
import { SiteContext } from "../context/index";
import { MotionWrap, classNames, Spinner, MasonryLayout } from "../components";
import {
  FaArrowRight,
  FaChevronCircleLeft,
  FaChevronCircleRight,
} from "react-icons/fa";
import { removeTags } from "../utils";
import { HiXCircle } from "react-icons/hi";

const PhotoGalleryHome = () => {
  const { userData, apiUrl, isLoggedOn } = useContext(SiteContext);

  const ref = useRef(null);

  const [loading, setLoading] = useState(false);
  const [homePhotos, setHomePhotos] = useState([]);
  const [homePhotoCat, setHomePhotoCat] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [photoCat, setPhotoCat] = useState([]);
  const [filteredPhotos, setFilteredPhotos] = useState([]);
  const [showFullPicture, setShowFullPicture] = useState(false);
  const [catSelected, setCatSelected] = useState("");
  const [itemSelected, setItemSelected] = useState({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const [cangoBack, setCangoBack] = useState(true);
  const [cangoForward, setCangoForward] = useState(true);
  const [selectedCat, setSelectedCat] = useState("All");
  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    getPhotosData();
  }, []);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      doSelected();
    }

    return () => {
      isMounted = false;
    };
  }, [homePhotos]);

  const getPhotosData = () => {
    return fetch(`${apiUrl}/api/fetch_home_photos.php`, {
      method: "get",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 200) {
          setHomePhotos(res.photos_rows);
          setHomePhotoCat(res.photo_cat);
        } else if (res.status === 400) {
        } else {
          console.log(
            "Error connecting to the server please check you details and try again"
          );
        }
      })
      .catch((error) => {
        console.log("Api call error", error.message);
      });
  };

  const doSelected = () => {
    setSelectedCat(homePhotoCat.id);
    setPhotos(homePhotos);
    setFilteredPhotos(homePhotos);
    setCurrentIndex(0);
  };

  const handleNext = () => {
    if (currentIndex === filteredPhotos.length - 1)
      setCurrentIndex(filteredPhotos.length - 1);

    if (currentIndex < filteredPhotos.length - 1) {
      setCurrentIndex((curr) => curr + 1);
    }
    handleItemSelected(currentIndex);
  };

  const handlePrev = () => {
    if (currentIndex === 0) setCurrentIndex(0);
    if (currentIndex > 0) {
      setCurrentIndex((curr) => curr - 1);
    }
    handleItemSelected(currentIndex);
  };

  const handleItemSelected = (id) => {
    let item = filteredPhotos.filter((item, i) => i === id);
    setItemSelected(item[0]);
    setShowFullPicture(true);
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div
      className={
        showFullPicture ? "relative bg-black bg-opacity-40" : "relative"
      }
    >

      <div className="flex justify-between content-center p2">
        <div className="bg-black text-white p-2 rounded">
          {homePhotoCat && homePhotoCat.cat}
        </div>
      </div>

      <div className="flex flex-col md:flex-row" style={{ maxWidth: "1300px" }}>
        <div classname="my-1 w-full" style={{ maxWidth: "1300px" }}>
          {catSelected !== "" && (
            <h1 className="font-bolder text-3xl p-2 my-2 bg-green-700 text-white rounded">
              {catSelected}
            </h1>
          )}
          {!loading && photoCat.length > 0 && catSelected === "" && (
            <div className="p-5 text-2xl">
              Click on any of the albums to view the pictures
            </div>
          )}
          {filteredPhotos && (
            <MasonryLayout
              images={filteredPhotos}
              handleItemSelected={handleItemSelected}
              apiUrl={apiUrl}
              type="photos"
              role=""
            />
          )}
        </div>
      </div>

      {showFullPicture && (
        <div
          ref={ref}
          className="absolute bg-slate-700 top-0 left-0 justify-center content-center w-full h-screen md:h-screen w-full z-120"
        >
          <div className="flex justify-center items-center">
            <FaChevronCircleLeft
              onClick={() => handlePrev()}
              size={30}
              className="absolute top-[150px] z-20 left-5 bg-white rounded-full cursor-pointer p-1"
            />
            <FaChevronCircleRight
              onClick={() => handleNext()}
              size={30}
              className="absolute top-[150px] z-20 right-5 bg-white rounded-full cursor-pointer ml-4 p-1"
            />
          </div>

          <div className="relative flex justify-between items-center h-[40px] z-100 p-4">
            <div className="text-white">{homePhotoCat && homePhotoCat.cat}</div>
            <div className="">
              <HiXCircle
                size={30}
                onClick={() => setShowFullPicture(false)}
                className="font-bold cursor-pointer bg-white text-black rounded-full p-0"
              />
            </div>
          </div>
          <div className="relative flex justify-center items-center">
            <img
              src={`${apiUrl}/${itemSelected.gphoto}`}
              alt={itemSelected.gname}
            />
            <div className="absolute bottom-0 bg-white p-2 text-medium rounded">
              {removeTags(itemSelected.gdesc)}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MotionWrap(PhotoGalleryHome, classNames);
