import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { Dropdown } from "react-bootstrap";
import ReactModal from "react-modal";
import { SiteContext } from "../context";

const DashboardMenu = () => {
  const navigate = useNavigate();

  const { isLoggedOn, isMobile, userData, handleSignOut } = useContext(SiteContext);
  const userDataStorage = JSON.parse(localStorage.getItem("user"));

  const userDataInfo = userData || userDataStorage;

  const [showModal, setShowModal] = useState(false);
  
  const doSignOut = () => {
      navigate('/')
      handleSignOut();
  }

  const AdminMenu = () => {
    return (
      <div>
        <div className="py-1 border-bottom">
          <Link className="my-1 text-decoration-none" to="/adminpages">
            Site Pages
          </Link>
        </div>
        <div className="py-1 border-bottom">
          <Link className="my-1 text-decoration-none" to="/adminevents">
            Events
          </Link>
        </div>
        <div className="py-1 border-bottom">
          <Link className="my-1 text-decoration-none" to="/admintributes">
            Tributes
          </Link>
        </div>
        <div className="py-1 border-bottom">
          <Link className="my-1 text-decoration-none" to="/users">
            Users
          </Link>
        </div>
        <div className="py-1 border-bottom">
          <Link className="my-1 text-decoration-none" to="/admincomments">
            Comments
          </Link>
        </div>
        <div className="py-1 border-bottom">
          <Link className="my-1 text-decoration-none" to="/adminphotogallery">
            Photo Gallery
          </Link>
        </div>
        <div className="py-1 border-bottom">
          <Link className="my-1 text-decoration-none" to="/adminvideogallery">
            Video Gallery
          </Link>
        </div>
        <div className="py-1 border-bottom">
          <Link className="my-1 text-decoration-none" to="/adminsiteconfig">
            Site Config
          </Link>
        </div>
      </div>
    );
  };

  return (
    <div>
      <ReactModal
        isOpen={showModal}
        style={{
          overlay: {
            flex: 1,
            justifyContent: "center",
            alignContent: "center",
            position: "fixed",
            top: "2%",
            bottom: "2%",
            left: 0,
            right: 0,
            width: "100%",
            height: "96%",
            backgroundColor: "rgba(255, 255, 255, 0.75)",
          },
          content: {
            position: "absolute",
            top: "10px",
            left: "10px",
            right: "10px",
            bottom: "10px",
            border: "1px solid #ccc",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            borderRadius: "4px",
            outline: "none",
            padding: "10px",
          },
        }}
      >
        <div>
          <div className="d-flex justify-content-end">
            <span
              className="btn btn-dark mb-2 py-1"
              onClick={() => setShowModal(false)}
            >
              <i className="bi bi-x h3 text-white"></i>
            </span>
          </div>
          <div className="py-3 px-1">
            {isLoggedOn ? (
              <div className="text-dark">
                <h5>Navigation {userDataInfo.role}</h5>
                <div className="py-1 border-bottom">
                  <Link className="my-1 text-decoration-none" to="/user">
                    Dashboard Home
                  </Link>
                </div>
                {userDataInfo && userDataInfo.role === "admin" ? (
                  <div className="my-2">
                    <AdminMenu />
                  </div>
                ) : null}

                <div className="py-1 border-bottom">
                  <Link className="my-1 text-decoration-none" to="/profile">
                    Profile
                  </Link>
                </div>
                <div className="py-1 border-bottom">
                  <Link
                    className="my-1 text-decoration-none"
                    to="/changepassword"
                  >
                    Change Password
                  </Link>
                </div>
              </div>
            ) : (
              <div>Not logged on</div>
            )}
          </div>
        </div>
      </ReactModal>

      {isMobile ? (
        <div className="p-3">
          <button className="btn-primary" onClick={() => setShowModal(true)}>
            <div className="flex justify-center items-center">
              Account Menu <FaBars className="ml-2" />
            </div>
          </button>
        </div>
      ) : (
        <div className="p-3">
          {isLoggedOn ? (
            <div className="text-dark">
              <h5>Navigation {userDataInfo.role}</h5>
              <div className="py-1 border-bottom">
                <Link className="my-1 text-decoration-none" to="/user">
                  Dashboard Home
                </Link>
              </div>
              {userDataInfo && userDataInfo.role === "admin" ? (
                <div className="my-2">
                  <AdminMenu />
                </div>
              ) : null}
              <div className="py-1 border-bottom">
                <Link className="my-1 text-decoration-none" to="/profile">
                  Profile
                </Link>
              </div>
              <div className="py-1 border-bottom">
                <Link
                  className="my-1 text-decoration-none"
                  to="/changepassword"
                >
                  Change Password
                </Link>
              </div>
              <div className="py-1 border-bottom">
                <span className='px-1'><button className='px-1 py-1 rounded bg-transparent text-dark' onClick={() => doSignOut()}>Log out <i className='bi bi-box-arrow-right h6'></i></button></span> 
              </div>
            </div>
          ) : (
            <div className="py-5 px-3 my-5">Not logged on</div>
          )}
        </div>
      )}
    </div>
  );
};

export default DashboardMenu;
