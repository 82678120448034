import React, { useState, useEffect, useContext } from "react";
import { Header, Footer, ProjectGallery } from "./";
import { Link, useNavigate, useParams } from "react-router-dom";
import { SiteContext } from "../context";
import {
  ImageDisplay,
  BannerTop,
  BannerSide,
  Comments,
  AppLayout,
  TwitterFeed,
  TopNews,
} from "../components";
import moment from "moment";
import { formatViews } from "../utils";
import Seo from "./Seo";
import { InlineShareButtons } from "sharethis-reactjs";

const EventDetail = () => {
  const {
    userData,
    apiUrl,
    siteconfig,
    homeEvents,
    isLoggedOn,
    categories,
    pages,
    handleCurrentPage,
    isMobile,
  } = useContext(SiteContext);

  const { title } = useParams();

  const navigate = useNavigate();

  const [animationPhotos, setAnimationPhotos] = useState([]);
  const [stories, setStories] = useState([]);
  const [event, setEvent] = useState({});
  const [storyArr, setStoryArr] = useState([]);
  const [story, setStory] = useState({});
  const [filteredOne, setFilteredOne] = useState([]);
  const [filteredFour, setFilteredFour] = useState([]);
  const [homeServices, setHomeServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    getEventDetail();
    window.scrollTo(0, 0);
  }, [title]);

  const getEventDetail = () => {
    if (title === "" || title === undefined) {
      setErrorMsg("Error retrieving events");
      return;
    }
    setStory({});

    setLoading(true);

    fetch(`${apiUrl}/api/fetch_event_detail.php?action=fetch&slug=${title}`, {
      method: "get",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 200) {
          let newsArr = res.event[0].eventdesc.split("</p>");

          setEvent(res.event[0]);
          setStory(res.event[0]);
          setStoryArr(newsArr);
          setLoading(false);
        } else if (res.status === 400) {
          setErrorMsg(res.msg);
          setLoading(false);
          setTimeout(() => {
            setErrorMsg("");
          }, 5000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const capitaliseText = (text) => {
    if (text === "") {
      return text;
    }
    let firstString = text.charAt(0);
    return firstString.toUpperCase() + "" + text.slice(1);
  };

  const doSanitize = (item) => {
    if (item === "" || item === undefined) {
      return item;
    }
    return item.replace(/<p>/g, "").replace(/<\/p>/g, " ");
  };

  const handleUpdate = (news) => {
    navigate("/updateevent", {
      state: { event: event, action: "update" },
    });
  };

  const getUrl = (url) => {
    if (isMobile) {
      let urlSplit = url.split("/");

      return `${apiUrl}/${urlSplit[0]}/${urlSplit[1]}/${urlSplit[2]}/${urlSplit[3]}/thumbnail/${urlSplit[4]}`;
    } else {
      return `${apiUrl}/${url}`;
    }
  };

  return (
    <div>
      <Seo
        title={
          event.hasOwnProperty("eventid")
            ? `${event.eventtitle} | ${siteconfig.shopname}`
            : `${siteconfig.shopname}`
        }
        description={event.hasOwnProperty("eventid") && event.eventdesc}
        url={`${apiUrl}/api/event_detail.php?id=${story.eventid}&title=${story.eventtitle_slug}`}
        img={
          event.hasOwnProperty("eventid") &&
          event.e_photo.includes("images/events")
            ? `${apiUrl}/${event.e_photo}`
            : event.e_photo
        }
      />

      {loading && (
        <div className="d-flex justify-content-center align-content-center my-5">
          <div class="spinner-border text-secondary" role="status" />
        </div>
      )}

      {errorMsg !== "" && <div className="container">{errorMsg}</div>}

      <div className="py-3 my-1">
        <div className="row">
          <div className="col-md-8 p-2">
            <h1 className="py-3 my-2">{event.eventtitle}</h1>

            {event.e_photo !== '' && event.e_photo !== null && <ImageDisplay
              url={`${apiUrl}/${event.e_photo}`}
              title={event.eventtitle}
              height={isMobile ? 300 : 400}
            />}

            <div className="my-3 py-2">
              <p>Date: {moment(event.e_date_start).format('Do MMM YYYY')}</p>
              <p>Time: {event.e_time_start} to {event.e_time_end}</p>
              <p>Venue: {event.e_place}</p>
            </div>

            <div
              style={{ overflowX: "auto" }}
              className="py-2"
              dangerouslySetInnerHTML={{
                __html: event.eventdesc,
              }}
            />

            {userData && userData.role === "admin" && (
              <div className="my-2 py-1">
                <a
                  className="h4 border text-decoration-none p-1 rounded"
                  onClick={() => handleUpdate(event)}
                >
                  <i className="bi bi-pencil text-dark"></i> Edit
                </a>
              </div>
            )}

            <Comments slug={event.eventtitle} section="Events" />

          </div>
        </div>
      </div>
    </div>
  );
};

export default AppLayout(EventDetail);
