import React from "react";
import { Link } from "react-router-dom";
import { InlineShareButtons } from "sharethis-reactjs";
import Carousel from "react-bootstrap/Carousel";
import {pic1, pic2, pic3, pic4} from '../constants'

const animationPhotos = [pic1, pic2, pic3, pic4]

const SideBar = () => {
  return (
    <div className="w-full">
      <div className="p-4 bg-slate-200 rounded text-slate-900 my-3">
       <h4 className="my-3 py-3">Share to Lucy's friends & family</h4>

       <InlineShareButtons
                        config={{
                          alignment: "center", // alignment of buttons (left, center, right)
                          color: "social", // set the color of buttons (social, white)
                          enabled: true, // show/hide buttons (true, false)
                          font_size: 16, // font size for the buttons
                          labels: "cta", // button labels (cta, counts, null)
                          language: "en", // which language to use (see LANGUAGES)
                          networks: [
                            // which networks to include (see SHARING NETWORKS)
                            "facebook",
                            "twitter",
                            "email",
                            "linkedin",
                            "whatsapp",
                            "messenger",
                          ],
                          padding: 12, // padding within buttons (INTEGER)
                          radius: 10, // the corner radius on each button (INTEGER)
                          show_total: false,
                          size: 40, // the size of each button (INTEGER)

                          // OPTIONAL PARAMETERS

                          url: 'https://lucyanikpoforever.com', // (defaults to current url)
                          image: `https://lucyanikpoforever.com/images/lucy.jpeg`, // (defaults to og:image or twitter:image)
                          description: `Lucy Aku Anikpo's memorial website`, // (defaults to og:description or twitter:description)
                          title: 'Lucy Aku Anikpo', // (defaults to og:title or twitter:title)
                          message: `Lucy Aku Anikpo's memorial website`, // (only for email sharing)
                          subject: `Lucy Aku Anikpo's memorial website`, // (only for email sharing)
                          username: '', // (only for twitter sharing)
                        }}
                      />


       
      </div>

      <div className="my-3 py-3">
      <Carousel
                style={{
                  backgroundColor: "black",
                  height: 250,
                }}
              >
                {animationPhotos.map((item,i) => {
                  return (
                    <Carousel.Item interval={3000} key={i}>
                     
                        <img
                          style={{
                            objectFit: "contain",
                            height: 250,
                            width: "100%",
                          }}
                          className="d-block w-100"
                          src={item}
                          height='auto'
                          width="100%"
                          alt={item}
                        />

                      <Carousel.Caption></Carousel.Caption>
                    </Carousel.Item>
                  );
                })}
              </Carousel>
              </div>

              <div className="p-4 bg-slate-800 rounded text-white my-3">
        <h4>Suggestion?</h4>
         <Link to='/contact' className="text-white text-decoration-none">Contact Us</Link>
      </div>

    </div>
  );
};

export default SideBar;
