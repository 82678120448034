import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import ReactModal from "react-modal";
import { Logo, BannerTop } from "../components";
import { logo } from "../constants";
import { SiteContext } from "../context";
import moment from "moment";

const Footer = () => {
  const { siteconfig, apiUrl, categories, handleCurrentPage } =
    useContext(SiteContext);

  const [itemSelected, setItemSelected] = useState({});
  const [showModal, setShowModal] = useState(false);

  return (
    <div>
      <div className="text-dark" style={{ backgroundColor: "#eee" }}>
        <div className="container">
        </div>
        <div className="py-1 text-white small bg-sky-800">
          <div className="container justify-content-start py-1">
            <p className="py-1 font-weight-bold text-center">
              Copyright{" "}
              <Link className="text-white text-decoration-none" to="/">
                {siteconfig.shopname}
              </Link>{" "}
              {moment().format("YYYY")} |{" "}
              <Link
                className="text-white text-decoration-none"
                to="/page/privacy-policy"
              >
                Privacy Policy
              </Link>{" "}
              |{" "}
              <Link
                className="text-white text-decoration-none"
                to="/page/terms-of-service"
              >
                Terms of Service
              </Link>{" "}
            </p>
            <i
              className="bi bi-chevron-up bg-white text-black rounded text-xl p-2 cursor-pointer float-right"
              onClick={() => window.scrollTo(0, 0)}
            ></i>
            <p className="py-1 font-weight-bold text-center">
              Developed by{" "}
              <a
                className="text-white text-decoration-none"
                href="https://justwebservices.com"
                target={"_blank"}
              >
                Just Web Services
              </a>
            </p>
            <div className='flex justify-center items-center my-2 py-1'>Need a memorial website? Contact via WhatsApp +2348033388070 | +2348085045730</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
