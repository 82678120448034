import { Link, useLocation } from "react-router-dom"

export const Button = ({title}) => {
    const currentPath = useLocation();

    const url = title === 'About' ? '/' : `/${title}`.toLowerCase()


    return (
        <Link className={currentPath.pathname=== url ? 'bg-white w-full py-2 bg-sky-600 text-dark text-center text-sm md:text-lg hover:bg-sky-900 text-slate-200' : 'w-full py-2 bg-sky-600 text-white text-center text-sm md:text-lg hover:bg-sky-900 text-slate-200'} to={`${url}`}><button className="text-center">{title}</button></Link>
    )
} 